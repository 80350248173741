import ws from "websocketjs"
import { v4 as uuidv4 } from "uuid"
export default class Socket {
  /**
   * socket创建
   * @param {String} url 链接路径
   * @param {Array} params 拼接参数
   */
  constructor(url, params) {
    this.msgFun = null
    //重连次数
    this.reConnect = 0
    //是否为主动断开链接
    this.autoClose = false
    this.url = url
    this.params = params
    const host = process.env.VUE_APP_WS_PATH
    const uuid = uuidv4().replace(/-/g, "")
    //拼接路径参数
    let path = `${host}/${url}`
    params.forEach((value) => {
      path = `${path}_${value}`
    })
    path = `${path}_${uuid}`
    //这里暂时不链接，只做创建socket对象
    this.ws = new ws(path, null, { automaticOpen: false })
    const _this = this
    this.ws.onopen = () => {
      _this._onSocketOpen()
    }
    this.ws.onclose = (event) => {
      _this._onSocketClose(event)
    }
    this.ws.onmessage = (msg) => {
      _this._onSocketMessage(msg)
    }
  }
  //当链接链接时
  _onSocketOpen() {
    console.warn("socket链接成功")
  }
  //当链接关闭时
  _onSocketClose(event) {
    console.error("socket断开链接")
    const code = event.code
    //短线重连
    if (this.reConnect < 6 && !this.autoClose) {
      this.reConnect++
      this.connect()
      console.log("尝试重连socket")
    }
  }
  _onSocketMessage(msg) {
    const data = JSON.parse(msg.data || {})
    if (data["heartbeat"] == true) return
    if (this.msgFun) {
      this.msgFun(data)
    }
  }
  /**
   * 链接socket
   */
  connect(onmessage) {
    if (this.ws.readyState != ws.OPEN) {
      this.autoClose = false
      this.msgFun = onmessage
      this.ws.open()
    } else {
      console.warn("socket当前正处于链接打开状态")
    }
  }
  /**
   * 链接关闭（用户主动关闭）
   */
  close() {
    this.autoClose = true
    this.ws?.close()
  }
  destroyed() {
    this.close()
    this.msgFun = null
    this.ws = null
  }
}
