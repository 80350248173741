import Axios from "axios"
import Vue from "vue"
import wrapper from "@/plugins/axios-cache-plugin"
const PUBLIC_PATH = "/hero/ent"
const axios = Axios.create({
  timeout: 60000,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
  },
})
const wrapperAxios = wrapper(axios, {
  maxCacheSize: 20, //最大缓存数量
  ttl: 5 * 60000, //5分钟，避免频繁切换加载慢问题
  excludeHeaders: false,
})
wrapperAxios.__addFilter(/hero/)
axios.interceptors.response.use(
  function (response) {
    const code = response.data.code
    if (code != 200) return Promise.reject({ code, msg: response.data.msg })
    return response.data
  },
  function (error) {
    return Promise.reject(error)
  }
)

export function get(path, params = {}) {
  const url = PUBLIC_PATH + path
  if (!params.projectId) {
    const store = Vue.prototype.$store
    let projectId = store.getters.projectId
    params.projectId = projectId
  }
  return wrapperAxios({
    url,
    method: "get",
    params: {
      ...params,
    },
  })
}
export function post(path, data = {}) {
  const store = Vue.prototype.$store
  const url = PUBLIC_PATH + path
  const projectId = store.getters.projectId
  return axios.post(url, {
    projectId,
    ...data,
  })
}

export default axios
