import jxyh from "@/view/jxyh/store"
import store, * as project from "@/view/project/store"

export const SET_PROJECT = project.SET_PROJECT
export const SET_PROJECT_LIST = project.SET_PROJECT_LIST
export const SET_LOADING = project.SET_LOADING
export const SET_CURREN_EQUIP = project.SET_CURREN_EQUIP
export const SET_CURREN_TIME = project.SET_CURREN_TIME
export const SET_COUNT_DATA = project.SET_COUNT_DATA
export const SET_COUNT_SELECT = project.SET_COUNT_SELECT
export const SET_RECORD_LIST = project.SET_RECORD_LIST
export const SET_CHART_DATA = project.SET_CHART_DATA
export const SET_CHART_SELECT = project.SET_CHART_SELECT
export const SET_IN_INFO = project.SET_IN_INFO
export const SET_OUT_INFO = project.SET_OUT_INFO

const location = document.location
const pathname = location.pathname
let export_store = null
if (pathname.indexOf("/jxyh") != -1) {
  export_store = jxyh
} else {
  export_store = store()
}
export default export_store
