import Vue from "vue"
import "@/styles/reset.css"
import "@/styles/common.css"
import "@/styles/element.css"
// import "@/plugins/echarts.js"
import VGaps from "@/components/v-gaps.vue"
import HGaps from "@/components/h-gaps.vue"
import SBox from "@/components/s-box.vue"
import FullscreenContainer from "vue-fullscreen-container"
import HikvisionVideo from "@/view/project/components/hikvision-video"
import scroll from "vue-seamless-scroll"
import Request from "@/mixins/request.js"
import "@/plugins/element.js"

Vue.use(HikvisionVideo)
Vue.use(FullscreenContainer)
Vue.use(scroll)
Vue.mixin(Request)
Vue.component("v-gaps", VGaps)
Vue.component("h-gaps", HGaps)
Vue.component("s-box", SBox)
