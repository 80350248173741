import Vue from "vue"
import "@/styles/reset.css"
import "@/styles/common.css"
import "@/styles/element.css"
import FullscreenContainer from "vue-fullscreen-container"
import scroll from "vue-seamless-scroll"
import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
import system from "@/plugins/system"
Vue.prototype.$system = system
Vue.use(ElementUI)
Vue.use(FullscreenContainer)
Vue.use(scroll)
