import Vuex from "vuex"
import Vue from "vue"
import socket from "./moudles/socket"
import { get } from "@/utils/request"
Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    socket,
  },
  state: {
    projectId: "",
    screenName: "",
    //设备列表
    devices: [],
    info: {
      id: "",
    },
  },
  mutations: {
    SET_PROJECT_ID(state, projectId) {
      state.projectId = projectId
    },
    SET_PROJECT_INFO(state, info) {
      state.info = info
    },
    SET_SCREEN_NAME(state, screenName) {
      state.screenName = screenName
    },
    SET_DEVICE_LIST(state, devices) {
      state.devices = devices
    },
  },
  getters: {
    projectId: (state) => state.projectId,
    screenName: (state) => state.screenName || "智慧大屏",
    devices: (state) => state.devices || [],
    lat: (state) => state.info.lat || 0,
    lng: (state) => state.info.lng || 0,
  },
  actions: {
    //获取项目信息
    GET_PROJECT_INFO({ commit }) {
      return new Promise((resolve, reject) => {
        get("/index/screen/projectInfo").then((res) => {
          if (res.code == 200) {
            commit("SET_PROJECT_INFO", res.data)
            resolve(res)
          } else {
            reject(res.msg)
          }
        })
      })
    },
    //获取大屏名称
    GET_SCREEN({ commit }) {
      return new Promise((resolve, reject) => {
        get("/pms/view/showName").then((res) => {
          if (res.code == 200) {
            commit("SET_SCREEN_NAME", res.data)
            resolve(res)
          } else {
            reject(res.msg)
          }
        })
      })
    },
    GET_PROJECT_DEVICE({ commit }) {
      get("/wss/environmentalMonitoring/findDeviceForComBox")
        .then((res) => {
          commit("SET_DEVICE_LIST", res.data || [])
        })
        .catch((_) => {})
    },
    INIT_PROJECT_DATA({ dispatch }) {
      dispatch("GET_PROJECT_INFO")
      dispatch("GET_SCREEN")
      dispatch("GET_PROJECT_DEVICE")
    },
  },
})
