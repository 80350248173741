const INTERVAL = 1 * 60 * 1000

export default {
    methods: {
        request(cb, interval = INTERVAL) {
            try {
                cb().catch(e => {
                    this.errors = true
                    return e
                }).finally(() => {
                    this.$loading = false
                })
            } catch(e) {
                // console.error(e)
            }
            this.cancel()
            this.timer = setTimeout(() => {
                this.request(cb, interval)
            }, interval)
        },
        cancel() {
            if(this.timer != null) window.clearInterval(this.timer)
            this.timer = null
        }
    },
    data () {
        return {
            errors: false,
            $loading: true
        }
    },
    destroyed() {
        clearTimeout(this.timer)
        this.timer = null
    }
}
