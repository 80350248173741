<template>
  <div class="hikvision-video">
    <big-screen
      v-if="typeNum == 0"
      :item="item"
      style="width: 100%; height: 100%"
      :typeNum="typeNum"
      :cameraIndexCode="cameraIndexCode"
      :channelNo="channelNo"
      :quality="quality"
    ></big-screen>
    <new-video
      :token="token"
      v-else
      style="width: 100%; height: 100%"
      :item="item"
      :typeNum="typeNum"
      @resetVideo="onReset"
      @save="onSave"
    ></new-video>
  </div>
</template>

<script>
import bigScreen from "../data-video/otherScreen.vue"
import NewVideo from "./live-video/new-video"
export default {
  name: "hikvision-video",
  props: {
    item: {
      type: Object,
    },
    typeNum: {
      type: [Number, String],
    },
    cameraIndexCode: {
      type: [String, Number],
    },
    channelNo: {
      type: [String, Number],
    },
    poster: {
      type: String,
    },
    token: {
      type: String,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    controls: {
      type: Boolean,
      default: false,
    },
    tips: {
      type: [Number, String],
    },
    quality: {
      type: [String, Number],
    },
  },
  components: {
    NewVideo,
    bigScreen,
  },
  data() {
    return {}
  },
  methods: {
    getToken() {
      getTokenCamera().then((data) => {
        this.token = data.data.accessToken
        this.initVideo(data.data.accessToken)
      })
    },
    onControlStart(key) {
      this.$emit("start", key)
    },
    onControlEnd() {
      this.$emit("end")
    },
    onReset() {
      this.$emit("reset")
    },
    onSave() {
      this.$emit("save")
    },
    openMessage(str) {
      this.$refs.liveVideo.openMessage(str)
    },
  },
}
</script>

<style scoped>
.hikvision-video {
  width: 100%;
  height: 100%;
}
</style>
