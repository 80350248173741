import axios from "@/utils/request"

const ROOT = "/hero/ent/index/screen"
//人员管理
const MENBER = "/hero/ent/crm/personnel"
//塔吊监控
const CRANE = "/hero/ent/tower/screen"
const ALARM = "/hero/ent/alarm"
//问题整改
const ISSUE = "/hero/ent/pr"
//环境监测
const ENV = "/hero/ent/wss"
//视频监控
const VIDEO = "/hero/ent/camera"
/// 和风天气 key
const HE_KEY = "bdbb313b9a564542aa713598dd64641e"

/// 获取项目清单
export function fetchProjectListByOrgId(orgId = "") {
  return axios.get(`${ROOT}/projectList?orgId=${orgId}`)
}

/// 获取项目信息
export function fetchProjectInfo(projectId = "") {
  return axios.get(`${ROOT}/projectInfo?projectId=${projectId}`)
}

// 获取今日考勤信息
export function fetchAttendanceInfo(projectId = "") {
  return axios.get(`${ROOT}/atteStats?projectId=${projectId}`)
}

//安全报警
export function fetchSaftyAlarm(projectId = "") {
  return axios.get(`${ROOT}/alarm?projectId=${projectId}`)
}

/// 环境监测
export function fetchEnvMonitorData(projectId = "", serialNo = "") {
  return axios.get(`${ROOT}/environmentMonitor?projectId=${projectId}&serialNo=${serialNo}`)
}

/// 人员超时未出场
export function fetchOvertimeList(projectId = "") {
  return axios.get(`${ROOT}/overtime/notLeaving?projectId=${projectId}`)
}
export function getTokenCamera() {
  return axios.get(`/hero/ent/camera/getFluoriteToken`)
}
export function getBigFullCamera(projectId = "") {
  return axios.get(`/hero/ent/camera/getMainCameraParam?projectId=${projectId}`)
}

/// 监控报警分布
export function fetchAlertData(projectId = "") {
  return axios.get(`${ROOT}/alarm/distribution?projectId=${projectId}`)
}

/// 劳务班组评分排名
export function fetchLaborRankList(projectId = "") {
  return axios.get(`${ROOT}/teamEvaList?projectId=${projectId}`)
}

/// 获取监控摄像头
export function fetchMonitor(projectId = "") {
  return axios.get(`${ROOT}/videoList?projectId=${projectId}`)
}

/// 获取在册人员情况
export function fetchRegisteredNumber(projectId = "") {
  return axios.get(`${MENBER}/registeredNumber?projectId=${projectId}`)
}

/// 获取年龄及性别分布
export function fetchAgeGroup(projectId = "") {
  return axios.get(`${MENBER}/ageGroup?projectId=${projectId}`)
}

/// 出勤趋势
export function fetchAttendanceTrend(id = "", type = "") {
  return axios.get(`${MENBER}/attendanceTrend?projectId=${id}&type=${type}`)
}

/// 工种分布
export function fetchWorkType(projectId = "") {
  return axios.get(`${MENBER}/workType?projectId=${projectId}`)
}

/// 今日考勤信息
export function fetchTodayAttendance(projectId = "") {
  return axios.get(`${MENBER}/todayAttendance?projectId=${projectId}`)
}

//安全教育
export function fetchSafetyTraining(projectId = "") {
  return axios.get(`${MENBER}/safetyTraining?projectId=${projectId}`)
}

/// 获取班组评分
export function fetchRankList(projectId = "") {
  return axios.get(`${ROOT}/teamScore?projectId=${projectId}`)
}

/// 和风天气 获取城市实时消息
export function fetchWeather(latlng = "") {
  return axios.get(`https://devapi.qweather.com/v7/weather/now?location=${latlng}&key=${HE_KEY}`)
}

//和风天气 获取城市未来五天消息
export function fetchThreeWeather(latlng = "") {
  return axios.get(`https://devapi.qweather.com/v7/weather/3d?location=${latlng}&key=${HE_KEY}`)
}

//获取塔吊设备列表
export function fetchMachineList(projectId = "") {
  return axios.get(`${CRANE}/machineList?projectId=${projectId}`)
}

//塔吊实时数据
export function fetchTowerRecord(machineId = "") {
  return axios.get(`${CRANE}/getTowerRecord?machineId=${machineId}`)
}

//今日安全报警
export function fetchWarningList(projectId = "") {
  return axios.get(`${ALARM}/tower/list?projectId=${projectId}`)
}

//历史报警统计
export function fetchHistoryCount(projectId = "", type = "") {
  return axios.get(`${ALARM}/tower/count?projectId=${projectId}&type=${type}`)
}

//获取问题整改问题概况
export function fetchOverview(projectId = "", queryCycle = 0) {
  return axios.get(`${ISSUE}/checkProblem/overview?projectId=${projectId}&queryCycle=${queryCycle}`)
}

//获取问题整改查询周期
export function fetchQueryCycle() {
  return axios.get(`${ISSUE}/query/cycle`)
}

//获取问题数据趋势
export function fetchProblemTrend(projectId = "", queryInterval = 7) {
  return axios.get(`${ISSUE}/checkProblem/trend/list?projectId=${projectId}&queryInterval=${queryInterval}`)
}

//获取遗留待整改问题
export function fetchAwaitRectifyList(projectId = "") {
  return axios.get(`${ISSUE}/awaitRectify/list?projectId=${projectId}`)
}

//获取设备
export function fetchFindDeviceForComBox(projectId = "") {
  return axios.get(`${ENV}/environmentalMonitoring/findDeviceForComBox?projectId=${projectId}`)
}

//视频监控--监控列表
export function getProjectCamera(projectId = "") {
  return axios.get(`${VIDEO}/getProjectCamera?projectId=${projectId}`)
}

//视频监控--监控信息
export function getCameraInfo(cameraIdList = []) {
  return axios.post(`${VIDEO}/getCameraInfo`, `${cameraIdList}`)
}

//获取设备设置
export function getSetting() {
  return axios.get(`${VIDEO}/getSetting`)
}

//获取设备设置
export function getToken() {
  return axios.get(`/hero/ent/camera/getToken`)
}

export function getFluoriteCloud(cameraIdList = []) {
  return axios.post(`/hero/ent/camera/getFluoriteCloud`, `${cameraIdList}`)
}

//塔吊视频
export function getScreenCamera(machineId = "", relCameraId = "", relCameraIndexCode = "") {
  return axios.get(`/hero/ent/tower/screen/getScreenCamera?machineId=${machineId}&relCameraId=${relCameraId}&relCameraIndexCode=${relCameraIndexCode}`)
}

//获取喷淋设备状态
export function getFindAutomaticSpray(projectId = "") {
  return axios.get(`/hero/ent/wss/environmentalMonitoring/findAutomaticSpray?projectId=${projectId}`)
}

// 修改喷淋设备状态
export function getEditAutomaticSpray(projectId = "", hasAutomatic = "", hasClose = "") {
  return axios.get(`/hero/ent/wss/environmentalMonitoring/editAutomaticSpray?projectId=${projectId}&hasAutomatic=${hasAutomatic}&hasClose=${hasClose}`)
}

//项目总览视频设备
export function getVideoDevice(projectId = "") {
  return axios.get(`/hero/ent/index/screen/videoDevice?projectId=${projectId}`)
}

const LIST = "/hero/ent/index/screen"

//获取在册工人列表
export function fetchWorkList(projectId = "", pageNum = 1, pageSize = 25) {
  return axios.get(`${LIST}/workList?projectId=${projectId}&pageNum=${pageNum}&pageSize=${pageSize}`)
}

//获取在册项目人员列表
export function fetchStaffList(projId = "", pageNum = 1, pageSize = 25) {
  return axios.get(`${LIST}/staffList?projId=${projId}&pageNum=${pageNum}&pageSize=${pageSize}`)
}

//获取在册班组列表
export function fetchTeamList(projectId = "", pageNum = 1, pageSize = 25) {
  return axios.get(`${LIST}/teamList?projectId=${projectId}&pageNum=${pageNum}&pageSize=${pageSize}`)
}

//专业分包  劳务分包
export function fetchSubPackList(projectId = "", subcontractType = 0, pageNum = 1, pageSize = 25) {
  return axios.get(`${LIST}/teamList?projectId=${projectId}&subcontractType=${subcontractType}&pageNum=${pageNum}&pageSize=${pageSize}`)
}

//获取在册总人数
export function fetchTotalList(projectId = "", pageNum = 1, pageSize = 25) {
  return axios.get(`${MENBER}/totalList?projectId=${projectId}&pageNum=${pageNum}&pageSize=${pageSize}`)
}

//历史塔吊数据列表
export function fetchTowerList(headId = "", pageNum = 1, pageSize = 25) {
  return axios.get(`${CRANE}/towerList?headId=${headId}&pageNum=${pageNum}&pageSize=${pageSize}`)
}

//获取天气历史数据
export function fetchHistoricalWeather(projectId = "") {
  return axios.get(`/hero/ent/wss/environmentalMonitoring/historicalWeather?projectId=${projectId}`)
}

//获取环境数据汇总
export function fetchEnvironmentSummary(projectId = "", type = "", serialNo = "") {
  return axios.get(`/hero/ent/wss/environmentalMonitoring/environmentSummary?projectId=${projectId}&type=${type}&serialNo=${serialNo}`)
}

//获取空气质量
export function fetchGetEnvironmentAQI(projectId = "", serialNo = "") {
  return axios.get(`/hero/ent/wss/environmentalMonitoring/getEnvironmentAQI?projectId=${projectId}&serialNo=${serialNo}`)
}

//获取安全报警列表
export function fetchEnvironmentalMonitoringalarm(projectId = "", machineCode = "") {
  return axios.get(`/hero/ent/wss/environmentalMonitoring/alarm?projectId=${projectId}&machineCode=${machineCode}`)
}

//获取主视频流
export function fetchGetMainCameraUrl(projectId = "", protocol = "") {
  return axios.get(`/hero/ent/camera/getMainCameraUrl?projectId=${projectId}&protocol=${protocol}`)
}

//获取多个视频流
export function fetchGetAllCameraUrl(cameraIdList = []) {
  return axios.post(`/hero/ent/camera/getAllCameraUrl`, `${cameraIdList}`)
}

//获取单个视频流
export function fetchGetCameraUrl(platform = "", cameraIndexCode = "", channelNo = "", protocol = "", quality = 1, projectId = "") {
  return axios.get(`/hero/ent/camera/getCameraUrl?platform=${platform}&cameraIndexCode=${cameraIndexCode}&channelNo=${channelNo}&protocol=${protocol}&quality=${quality}&projectId=${projectId}`)
}

//获取当前项目是否有视频权限
export function fetchCheckAuthorized(projectId = "") {
  return axios.get(`/hero/ent/camera/checkAuthorized?projectId=${projectId}`)
}

//首页大屏页签
export function fetchScreenList(projectId = "") {
  return axios.get(`/hero/ent/screen/screenList?projectId=${projectId}`)
}

//首页大屏页签
export function fetchModuleList(projectId = "", screenId = "") {
  return axios.get(`/hero/ent/screen/module/list?projectId=${projectId}&screenId=${screenId}`)
}

//防疫信息概况
export function fetchEpidemicInfo(projectId = "") {
  return axios.get(`/hero/ent/wss/epidemic/selectTotal?projectId=${projectId}`)
}

//测温明细
export function fetchEpidemicList(projectId = "", queryType = 0, pageNum = 1, pageSize = 1000) {
  return axios.get(`/hero/ent/wss/epidemic/list?projectId=${projectId}&queryType=${queryType}&pageNum=${pageNum}&pageSize=${pageSize}`)
}

//测温明细
export function fetchEpidemicQrCodeList(projectId = "", pageNum = 1, pageSize = 3) {
  return axios.get(`/hero/ent/wss/epidemic/qrCodeList?projectId=${projectId}&pageNum=${pageNum}&pageSize=${pageSize}`)
}

//车辆监控-监控列表
export function fetchProjectCamera(projectId = "") {
  return axios.get(`${VIDEO}/getProjectCamera?projectId=${projectId}`)
}

//车辆监控-获取视频流
export function fetchCameraUrl(data = {}) {
  return axios.post(`${VIDEO}/org/getCameraUrl`, data)
}

// 视频控制 开始
export function fetchControlStart(id, direction) {
  return axios.get(`${VIDEO}/controlStart?id=${id}&direction=${direction}`)
}

// 视频控制 结束
export function fetchControlEnd(id, direction) {
  return axios.get(`${VIDEO}/controlEnd?id=${id}&direction=${direction}`)
}

// 视频控制 重置位置
export function fetchMovePreset(id) {
  return axios.get(`${VIDEO}/movePreset?id=${id}`)
}

// 视频控制 收藏位置
export function fetchAddPreset(id) {
  return axios.get(`${VIDEO}/addPreset?id=${id}`)
}

// 获取大屏轮播状态
export function fetchRotateStatus(id) {
  return axios.get(`/hero/admin/company/projectManager/getRotateStatus?projectId=${id}`)
}

// 获取大屏轮播状态
export function fetchEditRotateStatus(projectId, hasRotate) {
  return axios.post(`/hero/admin/company/projectManager/editRotateStatus`, { projectId, hasRotate })
}
