<template>
  <router-view />
</template>

<script>
export default {}
</script>

<style>
html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
