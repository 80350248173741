<!-- 数据总览 写这个是因为只是看大屏，没有什么操作按钮 -->
<template>
  <div :loading="loading">
    <div id="video-container"></div>
  </div>
</template>

<script>
import EZUIKit from "ezuikit-js"
import { getTokenCamera } from "@/api"
export default {
  data() {
    return {
      msg: "",
      loading: true,
      isFullScreen: false,
      winFlag: false,
      player: null,
      document: "",
      token: "",
      hdFlag: "",
    }
  },
  props: {
    // 4 9 16
    typeNum: {
      type: [Number, String],
    },
    cameraIndexCode: {
      type: [String, Number],
    },
    channelNo: {
      type: [String, Number],
    },
    quality: {
      type: [String, Number],
    },
  },
  created() {
    this.getToken()
  },
  beforeDestroy() {
    this.destory()
  },
  methods: {
    destory() {
      if (this.player) {
        this.player.stop()
        this.player.destroy()
        this.player = null
      }
    },
    initVideo(token) {
      if (this.quality == 1) {
        this.hdFlag = ".hd"
      }
      this.player = new EZUIKit.EZUIKitPlayer({
        id: "video-container", // 视频容器ID
        accessToken: token,
        template: "simple",
        url: `ezopen://open.ys7.com/${this.cameraIndexCode}/${this.channelNo}${this.hdFlag}.live`,
        audio: 0,
        header: ["capturePicture"],
        width: 800,
        height: 469,
        handleSuccess: () => {
          this.loading = false
        },
        handleError: (e) => {
          this.loading = false
          this.$message.error(e)
        },
      })
      this.player.play()
    },

    // 获取萤石云Token
    getToken() {
      getTokenCamera().then((data) => {
        this.initVideo(data.data.accessToken)
      })
    },
  },
}
</script>
<style lang="less" scoped>
.newVideo {
  position: relative;
  overflow: hidden;
  width: 700px;
  height: 400px;
  background: rgb(0, 0, 0);
}

.videoSelf {
  width: 100%;
  height: 100%;
  object-fit: fill !important;
}

.ezuikit-js {
  width: 100%;
  height: 100%;
}

.test {
  border-radius: 5px;
  font-size: 20px;
  color: #333;
  background: white;
  margin: 10px 0;
  padding: 5px;
  margin-right: 10px;
}

.show {
  display: inline-block;
}
</style>
