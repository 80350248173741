import Socket from "@/utils/socket"
import Vue from "vue"
export default {
  namespaced: true,
  state: {
    //环境数据
    socketData: {},
    socketMap: {},
  },
  getters: {
    //实时监测数据详情
    socketData: (state) => state.socketData || {},
  },
  mutations: {
    SET_SOCKET_DATA(state, data) {
      Vue.set(state.socketData, data.path, data.msg)
    },
    //缓存socket对象
    SET_SOCKET(state, { path, socket }) {
      state.socketMap[path] = socket
    },
  },
  actions: {
    /**
     * 链接环境数据socket
     * @param {String} path 路径
     * @param {Array} values 参数，请注意后端要求的参数顺序
     */
    CONNECT_SOCKET({ state, commit }, { path, values }) {
      let socket = state.socketMap[path]
      if (!socket) {
        socket = new Socket(path, values)
        commit("SET_SOCKET", { path, socket })
      }
      socket.connect((msg) => {
        commit("SET_SOCKET_DATA", { path, msg })
      })
    },
    /**
     * 断开指定路径的socket
     * @param {String} path 路径
     */
    CLOSE_SOCKET({ commit, state }, path) {
      let socket = state.socketMap[path]
      if (socket) {
        socket.close()
      }
    },
  },
}
