const mainMap = {
  jxyh: "./jxyh",
  qmodel: "./qmodel",
  monitor: "./monitor",
  "/": "./project",
}
const findConfig = function () {
  const location = document.location
  const inst = `${process.env.VUE_APP_BASE}`
  const pathname = location.pathname.replace(inst, "")
  let keys = pathname.split("/")
  keys = keys.filter((item) => {
    return item.length
  })
  if (keys.length == 0) keys = ["/"] //当长度为0时，为根目录。
  const key = keys[0]
  let path = mainMap[key]
  if (!path) return "/"
  return path
}
require(`${findConfig()}`)
