import Vue from "vue"
import VueRouter from "vue-router"
import routes from "./routes"
Vue.use(VueRouter)
//处理跳转当前路由报错问题。
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
// 创建router实例
const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE,
  routes,
})
export default router
