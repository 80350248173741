import Vue from "vue"
import App from "./App.vue"
import store from "@/store"
import router from "@/router"
//根据条件注册插件
import "@/main/index"
Vue.config.productionTip = false
Vue.prototype.$store = store
new Vue({
  store: store,
  router,
  render: (h) => h(App),
}).$mount("#app")
