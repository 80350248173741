<template>
  <div :id="videroId"></div>
</template>

<script>
import EZUIKit from "ezuikit-js"
import { getTokenCamera } from "@/api"
export default {
  data() {
    return {
      msg: "",
      loading: true,
      isFullScreen: false,
      winFlag: false,
      player: null,
      document: "",
      width: 900,
      height: 400,
      hdFlag: "",
      // token: ''
    }
  },
  props: {
    item: {
      type: Object,
    },
    // 4 9 16
    typeNum: {
      type: [Number, String],
    },
    token: {
      type: String,
    },
  },
  // 800 460 550 256
  computed: {
    videroId() {
      return this.item.channelNo + "_" + this.item.cameraIndexCode.replace(":", "")
    },
  },
  watch: {
    typeNum: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        // newVal = 4\9\16是视频监控的；
        // newVal = 1是起重机械的；
        // newVal = 2是车辆管理的；
        if (newVal == oldVal) {
          return
        }
        if (newVal == 1) {
          this.width = 936
          this.height = 592
        } else if (newVal == 2) {
          this.width = 800
          this.height = 502
        } else if (newVal == 4) {
          this.width = 900
          this.height = 400
        } else if (newVal == 9) {
          this.width = 600
          this.height = 280
        } else {
          this.width = 450
          this.height = 200
        }
      },
    },
    item: {
      immediate: true,
      deep: true,
      handler() {
        this.initVideo(this.token)
      },
    },
  },
  created() {
    window.addEventListener("resize", this.onresize)
  },
  // mounted() {
  //     this.initVideo(this.token)
  // },
  beforeDestroy() {
    if (this.player) {
      this.player.stop()
      this.player.destroy()
      this.player = null
    }
    window.addEventListener("resize", this.onresize)
  },
  methods: {
    onresize(e) {
      this.winFlag = window.innerHeight === window.screen.height
      let isFull = window.fullScreen || document.webkitIsFullScreen
      if (isFull === undefined) {
        this.isFullScreen = this.winFlag
      } else {
        this.isFullScreen = this.winFlag || isFull
      }
      var a = document.getElementById(`${this.videroId}-ez-ptz-item`)

      // var b = document.getElementById(`${this.videroId}-pantile`)
      var c = document.getElementById(`${this.videroId}-mobile-ez-ptz-item-save`)
      var d = document.getElementById(`${this.videroId}-mobile-ez-ptz-item-reset`)
      // var f = document.getElementById(`${this.videroId}-zoom-container`)
      if (this.winFlag == true) {
        c.style.display = "inline-block"
        d.style.display = "inline-block"
        a.style.display = "inline-block"
        // f.style.display = 'inline-block'
      } else {
        if (this.item && this.item.cameraType !== 1) {
          b.style.display = "none"
        }
        a.style.display = "none"
        c.style.display = "none"
        d.style.display = "none"
        // f.style.display = 'none'
      }
    },
    play() {
      var playPromise = this.player.play()
      playPromise.then((data) => {
        console.log("promise 获取 数据", data)
      })
    },
    stop() {
      var stopPromise = this.player.stop()
      stopPromise.then((data) => {
        console.log("promise 获取 数据", data)
      })
    },
    initVideo() {
      console.log("重新加载")
      if (this.item.quality == 1) {
        this.hdFlag = ".hd"
      }
      this.player = new EZUIKit.EZUIKitPlayer({
        id: this.videroId, // 视频容器ID
        accessToken: this.token,
        template: "5b820bcf7a334a9d88110b91b4cdebde", // 5
        url: `ezopen://open.ys7.com/${this.item.cameraIndexCode}/${this.item.channelNo}${this.hdFlag}.live`,
        audio: 0,
        header: ["capturePicture"],
        width: this.width,
        height: this.height,
        handleSuccess: () => {
          var b = document.getElementById(`${this.videroId}-pantile`)
          if (this.item.cameraType == 1) {
            this._initFooterBar()
            b.style.display = "inline-block"
          } else {
            b.style.display = "none"
          }
        },
        handleError: (e) => {
          // this.player.stop();
        },
      })
      this.player.play()
    },

    // 获取萤石云Token
    getToken() {
      getTokenCamera().then((data) => {
        this.token = data.data.accessToken
        this.initVideo(data.data.accessToken)
      })
    },
    _initFooterBar() {
      const document = window.document.getElementById(`${this.videroId}-audioControls-left`)
      var savetDiv = window.document.createElement("div")
      savetDiv.id = `${this.videroId}-mobile-ez-ptz-item-save`
      if (this.typeNum == 16) {
        savetDiv.style = "display:none;color:#333;font-size: 5px;cursor: pointer; background: white; border-radius: 4px; margin: 5px 0 5px 20px;white-space: nowrap;padding: 6px;text-align: center;line-height: 8px;box-sizing: border-box;"
      } else {
        savetDiv.style = "display:none;color:#333;font-size: 10px;cursor: pointer; background: white; border-radius: 4px; margin: 10px 0 10px 30px;white-space: nowrap;padding: 6px;text-align: center;line-height: 16px;box-sizing: border-box;"
      }

      savetDiv.innerHTML = "收藏点位"
      document.appendChild(savetDiv)
      savetDiv.addEventListener("click", (e) => {
        if (window.saveVideo) {
          window.saveVideo.postMessage(true)
        } else {
          this.$emit("save")
        }
      })
      var resetDiv = window.document.createElement("div")
      resetDiv.id = `${this.videroId}-mobile-ez-ptz-item-reset`
      if (this.typeNum == 16) {
        resetDiv.style = "display:none;color:#333;font-size: 5px;cursor: pointer; background: white; border-radius: 4px; margin: 5px 0 5px 20px;white-space: nowrap;padding: 6px;text-align: center;line-height: 8px;box-sizing: border-box;"
      } else {
        resetDiv.style = "display:none;color:#333;font-size: 10px;cursor: pointer; background: white; border-radius: 4px; margin: 10px 0 10px 30px;white-space: nowrap;padding: 6px; vertical-align:middle;text-align: center;line-height: 16px;box-sizing: border-box;"
      }
      resetDiv.innerHTML = "重置点位"
      document.appendChild(resetDiv)
      resetDiv.addEventListener("click", (e) => {
        if (window.resetVideo) {
          window.resetVideo.postMessage(true)
        } else {
          this.$emit("resetVideo")
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
.newVideo {
  position: relative;
  overflow: hidden;
  width: 700px;
  height: 400px;
  background: rgb(0, 0, 0);
}

.videoSelf {
  width: 100%;
  height: 100%;
  object-fit: fill !important;
}

.ezuikit-js {
  width: 100%;
  height: 100%;
}

.test {
  border-radius: 5px;
  font-size: 20px;
  color: #333;
  background: white;
  margin: 10px 0;
  padding: 5px;
  margin-right: 10px;
}

.show {
  display: inline-block;
}
</style>
