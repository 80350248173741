import Vuex from "vuex"
import Vue from "vue"

Vue.use(Vuex)

export const SET_PROJECT = "set_project"
export const SET_PROJECT_LIST = "set_project_list"
export const SET_LOADING = "set_loading"
export const SET_CURREN_EQUIP = "set_curren_equip"
export const SET_CURREN_TIME = "set_curren_time"
export const SET_COUNT_DATA = "set_count_data"
export const SET_COUNT_SELECT = "set_count_select"
export const SET_RECORD_LIST = "set_record_list"
export const SET_CHART_DATA = "set_chart_data"
export const SET_CHART_SELECT = "set_chart_select"
export const SET_IN_INFO = "set_in_info"
export const SET_OUT_INFO = "set_out_info"

export default function () {
  const store = new Vuex.Store({
    state: {
      loading: true,
      /// 当前项目
      project: {
        id: "",
        name: "",
        location: "",
        address: "",
      },
      /// 项目列表
      projectList: [],
      currenEquip: [], //当前塔吊设备信息
      currenData: 0, //当前问题整改日期
      countData: {
        dayCount: {
          inCount: 0,
        },
      },
      countSelectBoxData: {},
      recordList: [],
      chartData: {},
      chartDataSelectBoxData: [],
      inInfo: {},
      outInfo: {},
    },
    getters: {
      projectId: (state) => state.project.id,
      projectList: (state) => state.projectList,
      projectName: (state) => (state.loading ? "暂无项目" : state.project.name),
      latlng: (state) => state.project.location,
      loading: (state) => state.loading,
      currenEquip: (state) => state.currenEquip,
      currenData: (state) => state.currenData,
      setCountData: (state) => state.countData,
      setCountSelect: (state) => state.countSelectBoxData,
      recordList: (state) => state.recordList,
      chartData: (state) => state.chartData,
      chartDataSelectData: (state) => state.chartDataSelectBoxData,
      inInfo: (state) => state.inInfo,
      setOutInfo: (state) => state.outInfo,
    },
    actions: {
      [SET_LOADING]: ({ commit }, loading) => {
        commit(SET_LOADING, loading)
      },
      [SET_PROJECT]: ({ commit }, project = {}) => {
        commit(SET_PROJECT, project)
      },
      [SET_PROJECT_LIST]: ({ commit }, { data: projectList, projectId }) => {
        commit(SET_PROJECT_LIST, projectList)
        /// 默认选中第一个 如果地址栏传入了项目id 那么获取该项目
        let index = projectList.findIndex((project) => project.id === projectId)
        if (index === -1) {
          commit(SET_PROJECT, projectList[0])
        } else {
          commit(SET_PROJECT, projectList[index])
        }
        commit(SET_LOADING, false)
      },
      [SET_CURREN_EQUIP]: ({ commit }, { currenEquip }) => {
        commit(SET_CURREN_EQUIP, currenEquip)
      },
      [SET_CURREN_TIME]: ({ commit }, { currenData }) => {
        commit(SET_CURREN_TIME, currenData)
      },
      [SET_COUNT_DATA]: ({ commit }, { countData }) => {
        commit(SET_COUNT_DATA, countData)
      },
      [SET_COUNT_SELECT]: ({ commit }, { countSelectBoxData }) => {
        commit(SET_COUNT_SELECT, countSelectBoxData)
      },
      [SET_RECORD_LIST]: ({ commit }, { recordList }) => {
        commit(SET_RECORD_LIST, recordList)
      },
      [SET_CHART_DATA]: ({ commit }, { chartData }) => {
        commit(SET_CHART_DATA, chartData)
      },
      [SET_CHART_SELECT]: ({ commit }, { chartDataSelectBoxData }) => {
        commit(SET_CHART_SELECT, chartDataSelectBoxData)
      },
      [SET_IN_INFO]: ({ commit }, { inInfo }) => {
        commit(SET_IN_INFO, inInfo)
      },
      [SET_OUT_INFO]: ({ commit }, { outInfo }) => {
        commit(SET_OUT_INFO, outInfo)
      },
    },
    mutations: {
      [SET_PROJECT]: (state, project) => (state.project = project),
      [SET_PROJECT_LIST]: (state, projectList) => (state.projectList = projectList),
      [SET_LOADING]: (state, loading) => (state.loading = loading),
      [SET_CURREN_EQUIP]: (state, currenEquip) => (state.currenEquip = currenEquip),
      [SET_CURREN_TIME]: (state, currenData) => (state.currenData = currenData),
      // currenData: state => state.currenData,
      [SET_COUNT_DATA]: (state, countData) => (state.countData = countData),
      [SET_COUNT_SELECT]: (state, countSelectBoxData) => (state.countSelectBoxData = countSelectBoxData),
      [SET_RECORD_LIST]: (state, recordList) => (state.recordList = recordList),
      [SET_CHART_DATA]: (state, chartData) => (state.chartData = chartData),
      [SET_CHART_SELECT]: (state, chartDataSelectBoxData) => (state.chartDataSelectBoxData = chartDataSelectBoxData),
      [SET_IN_INFO]: (state, inInfo) => (state.inInfo = inInfo),
      [SET_OUT_INFO]: (state, outInfo) => (state.outInfo = outInfo),
    },
  })
  return store
}
