<template>
  <div
    class="s-box"
    :style="{ width: `${width}px`, height: `${height}px`, marginLeft }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  data() {
    return {
      width: "1920",
      height: "1080",
      marginLeft: "182px",
    }
  },
  mounted() {
    this.resizeBox()
    const _this = this
    window.addEventListener("resize", () => {
      _this.resizeBox()
    })
  },
  methods: {
    resizeBox() {
      let scale = document.body.clientWidth / 1920
      let marginLeft = "182px"
      const screen = document.getElementsByClassName("v-fullscreen-container")[0]
      if (screen) {
        scale = screen.style.transform.replace("scale(", "").replace(")", "")
        scale > 1 ? (scale = 1) : undefined
        marginLeft = screen.style.marginLeft
      }
      this.width = 1920 * scale
      this.height = 980 * scale
      this.marginLeft = marginLeft
      this.$children[0].updateScale(scale)
      // if (scale < 0.9) {
      //   this.marginLeft = "160px"
      //   this.$children[0].updateScale(0.96)
      // } else {
      //   this.marginLeft = "182px"
      //   this.marginTop = "0px"
      //   this.$children[0].updateScale(0.81)
      // }
    },
  },
}
</script>

<style lang="less" scoped>
.s-box {
  margin-left: 182px;
  margin-top: 0px;
  /deep/.screen {
    .header {
      transform: scale(0.810185);
      transform-origin: left top;
      transition: all 0s linear 0s;
    }
  }
}
</style>
