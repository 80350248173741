export default [
  {
    path: "/",
    name: "Home",
    meta: {
      title: "裕华聚以前旧的大屏，项目级大屏酯大屏",
    },
    component: () => import("@/view/project/main.vue"),
  },
  {
    path: "/jxyh",
    name: "jxyh",
    meta: {
      title: "裕华聚酯大屏",
    },
    component: () => import("@/view/jxyh/main.vue"),
    children: [
      {
        path: "/",
        meta: {
          title: "控制中心",
          //指定背景图片名称
          bgName: "img_zjmx",
          //指定项目背景图片名称
          39941432740131840: "39941432740131840",
        },
        component: () => import("@/view/jxyh/pages/centerManger/index.vue"),
      },
      {
        path: "labor",
        name: "laborServices",
        meta: {
          title: "劳务实名制大屏",
        },
        component: () => import("@/view/jxyh/pages/laborServices/index.vue"),
      },
      {
        path: "material",
        name: "material",
        meta: {
          title: "材料管理大屏",
        },
        component: () => import("@/view/jxyh/pages/material/index.vue"),
      },
      {
        path: "safetyManagement",
        name: "safetyManagement",
        meta: {
          title: "安全管理大屏",
        },
        component: () => import("@/view/jxyh/pages/safetyManagement/index.vue"),
      },
      {
        path: "progressManagement",
        name: "progressManagement",
        meta: {
          title: "进度管理大屏",
        },
        component: () => import("@/view/jxyh/pages/progressManagement/index.vue"),
      },
      {
        path: "bim",
        name: "bim",
        meta: {
          title: "BIM协同管理大屏",
          unKeep: true,
        },
        component: () => import("@/view/jxyh/pages/bim/index.vue"),
      },
      {
        path: "quality",
        name: "quality",
        meta: {
          title: "质量管理大屏",
        },
        component: () => import("@/view/jxyh/pages/quality/index.vue"),
      },
      {
        path: "bicarbon",
        name: "bicarbon",
        meta: {
          title: "双碳管理",
        },
        component: () => import("@/view/jxyh/pages/bicarbon/index.vue"),
      },
      {
        path: "monitor",
        name: "monitor",
        meta: {
          title: "视频监控",
        },
        component: () => import("@/view/jxyh/pages/monitor/index.vue"),
      },
      {
        path: "robot",
        name: "robot",
        meta: {
          title: "环境监测",
        },
        component: () => import("@/view/jxyh/pages/robot/index.vue"),
      },
    ],
  },
  {
    path: "/qmodel",
    name: "qmodel",
    meta: {
      title: "qmodel加载",
    },
    component: () => import("@/view/qmodel/index.vue"),
  },
  {
    path: "/monitor",
    name: "monitor",
    meta: {
      title: "萤石云视频播放",
    },
    component: () => import("@/view/monitor/index.vue"),
  },
]
